<template>
    <section>
        <navbar-admin titulo="configuración permisos" icon="icon-cog"></navbar-admin>
        <redirect/>
        <router-view />
    </section>
</template>

<script>
import redirect from './components/redirect.vue'
export default {
    components:{
        redirect,
    }
}
</script>

<style>

</style>