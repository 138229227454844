<template>
    <div class="menu">
        <el-button-group>
            <el-button :class="actionIsActive" icon="el-icon-arrow-left" @click="redirect('configurar.permisos.acciones')">Acciones</el-button>
            <el-button :class="routeIsActive" @click="redirect('configurar.permisos.rutas')">Rutas <i class="el-icon-arrow-right"></i></el-button>
        </el-button-group>
    </div>   
</template>

<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        routeIsActive() {
            return this.$route.name == 'configurar.permisos.rutas' ? 'btn_ruta_active' : 'btn_ruta'
        },
        actionIsActive(){
            return this.$route.name == 'configurar.permisos.acciones' ? 'btn_ruta_active' : 'btn_ruta'
        }
    },
    methods:{
        redirect(route){
            if(!route) return;
            this.$store.commit('menu/SHOW_MENU', false)
            this.$emit('hideMenu');
            this.navigateTo({ name: route });
        }
    }
}
</script>

<style scoped>
.menu {
    padding: 25px;
    display: flex;
    justify-content: center;
}
.btn_ruta {
    color: var(--color-general);
    border-radius: 4px;
    width: 150px;
}
.btn_ruta_active {
    background-color: var(--color-general);
    color: #fff;
    border-radius: 4px;
    width: 150px;
}
</style>